<template>
<div>   
	  <XModal v-if="popupData && popupData.line" :name="name" :resizable="true" :width="width+'%'" 
	          :height="height+'%'" :draggable="true" >
      <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
		  <div class='appHead' >
	             <div style='display: inline-flex; float: left; padding-top: 2pt; margin-right: 15pt;'>
	             <div :style="'padding: 3pt; color: '+getFGColor('#000','#008')">
	            	 Complete data for {{popupData.line.name}}
	                </div>
	             </div>
	            
	              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
	              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
					  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
					  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
					</svg>
				  </span>
	              </button>
	            </div> <!-- appHead -->
	            
	       <div class="modal-header" :style='getAppStyle()' >
	          
	        </div> <!-- modal-header -->  	  
	        <div class="modal-body" id='main-body' :style='getAppStyle()'>
		  
		      <div :style='getTableStyle()' >
	          <slot name="body">
	           
	            <div class='mainContent' >
	               <table width='100%'>
	               <tr>
		              <td style='width: 15%;'>Advertiser</td><td style='width: 30%;'><InputText :border="false" v-model='advertiserName'/></td>
		              <td style='width: 40%;'>
		              <GSelectSimple create="true" :showOpener="true" entity='Client' :options="list['Advertiser']" v-model="popupData.line.advertiserId"/>
		              
		              </td> 
	               </tr>
	               <tr>
		              <td style='width: 15%;'>Agency</td><td style='width: 30%;'><InputText :border="false" v-model='agencyName'/></td>
		              <td style='width: 40%;'>
		              <GSelectSimple create="true" :showOpener="true" entity='Client' :options="list['Agency']" v-model="popupData.line.agencyId"/>
		              </td> 
	               </tr>
	               <tr>
		              <td :style="getStyle('Product')" @click="disable('Product')">{{tr('Product')}}</td><td style='width: 30%;'>
						<InputText :border="false" v-model='productName'/></td>
		              <td style='width: 40%;'>
		                <GSelectSimple  create="true" :createWith="{clientId: popupData.line.advertiserId, name: productName}" :createIf="popupData.line.advertiserId"
						                :showOpener="true" entity='Product' :options="list['Product']" v-model="popupData.line.productId"/>
						
		              </td> 
	               </tr>
	               <tr>
		              <td :style="getStyle('Currency')" @click="disable('Currency')">{{tr('Currency')}}</td><td colspan="2" style='width: 70%;'>
		              <GSelectSimple :showOpener="true" entity='Currency' :nulls="false" :options="list['Currency']" v-model="popupData.line.currencyId"/>
		              </td> 
	               </tr>
	               <tr>
		              <td style='width: 15%;'>{{tr('SalesUnit')}}</td><td colspan="2" style='width: 70%;'>
		              <GSelectSimple :showOpener="true" entity='SalesUnit' :options="list['SalesUnit']" v-model="popupData.line.salesUnitId"/>
		              </td> 
	               </tr>
	               <tr><td colspan=3 height=20>&nbsp;</td></tr>
	               <tr style='font-size: 8pt;'><td colspan=3 height=20>
	               <span :style='getComplStyle(popupData.line.advertiserId)'>Advertiser</span><Checked :show="popupData.line.advertiserId"/>
	               <span :style='getComplStyle(popupData.line.agencyId)'>Agency</span><Checked :show="popupData.line.agencyId"/>
	               <span :style='getComplStyle(popupData.line.productId)'>{{tr('Product')}}</span><Checked :show="popupData.line.productId"/>
				   <span :style='getComplStyle(popupData.line.salesUnitId)'>{{tr('SalesUnit')}}</span><Checked :show="popupData.line.salesUnitId"/>
				   <span :style='getComplStyle(popupData.line.currencyId)'>{{tr('Currency')}}</span><Checked :show="popupData.line.currencyId"/>
				   <span :style='getComplStyle(popupData.line.campaignId)'>{{tr('Campaign')}}</span><Checked :show="popupData.line.campaignId"/>
				   <span :style='getComplStyle(popupData.line.placementId)'>{{tr('Placement')}}</span><Checked :show="popupData.line.placementId"/>
	               </td></tr>
	               <tr><td colspan=3 height=20>&nbsp;</td></tr>
	               <tr><td colspan=3>
	              
	               <Button class='button' :style='getAppStyle()' @click="searchCampaign">Search {{tr('Campaign')}}</button>
	               
	               <Button class='button' :style='getAppStyle()' :disabled='!popupData.line.campaignId' @click="searchPlacement">Search {{tr('Placement')}}</button>
	               <Button class='button' :style='getAppStyle()' :disabled='searchFirst' @click="openCampaignWizard">Create {{tr('Campaign')}}</button>
	               <Button class='button' :style='getAppStyle()' :disabled='searchFirstPlcm || !popupData.line.campaignId' @click="openWizard">Create {{tr('Placement')}}</button>
	               </td>
	               </tr>
	               <tr><td colspan=3 height=20>&nbsp;</td></tr>
	               <tr>
		              <td  style='width: 15%;'>{{tr('Campaign')}}
		             
		              </td><td colspan="2" style='width: 70%;'>
		              <GSelectSimple  v-if="list['Campaign'] && list['Campaign'].length" :showOpener="true" entity='Campaign' 
		                              @input="campaignChanged"
		                              :options="list['Campaign']" v-model="campaignId"/>
		             
		              </td> 
	               </tr>
	               <tr>
		              <td  style='width: 15%;'>{{tr('Placement')}}</td><td colspan="2" style='width: 70%;'>
		              <GSelectSimple  v-if="list['Placement'] && list['Placement'].length" :showOpener="true" entity='Placement' :options="list['Placement']" v-model="popupData.line.placementId"/>
		             
		              </td> 
	               </tr>
	               </table>
	               <div style='position: absolute; float: bottom; width: 100%; bottom: 0;  padding-right: 15pt; padding-bottom: 8pt;'>
	                 <Button :disabled="!canSave()" :style="getAppStyle()+'float:right;'" class='button btn-save' @click="save">Save</button>
					 <Button :style="getAppStyle()+'float:right;'" class='button' @click="reloadAll()"><mdicon size="20" name="reload"/></button>
	                 <Button :disabled="!isCompleted()" :style="getAppStyle()+'float:right;'" class='button' @click="completed">Completed</button>
	                 <Button :disabled="!canSave()" :style="getAppStyle()+'float:right;'" class='button' @click="close">Close</button>
	               </div>
				</div>
	
		      </slot>
		      </div>
		     
		    </div>
	  	</div>
	  </XModal>
	  
	<GFWEOpenEditor ref='editor'></GFWEOpenEditor>
	<PlacementWizard ref='placementWizard' @placement='placementCreated' @openInvEditor="openInvEditor"></PlacementWizard>
	<CampaignWizard ref='campaignWizard' @campaign='campaignCreated'></CampaignWizard>
	<GConfirm ref='confirm'/>
	
</div>
</template>


<script>
import {HTTP, opsAPI, fwAPI, invAPI, setReload, showError} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getFGColor } from '@/AppStyle.js';
import { tr } from '@/translate.js';
import InputDateTime from '@/components/inputElements/InputDateTime';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputText from '@/components/inputElements/InputText';
import GSelectSimple from '@/components/GSelectSimple';
import Checked from '@/components/Ops/Checked';
import GConfirm from '@/components/GConfirm';
import ClickOutside from 'vue-click-outside'
import PlacementWizard from '@/components/Ops/PlacementWizard';
import CampaignWizard from '@/components/Ops/CampaignWizard';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: 'GOPS_SalesRequestCompletePopup',
  components : {
   InputText, GSelectSimple, Checked, GConfirm, PlacementWizard, CampaignWizard
  },
  props: {
    name: {
      type: String,
      default: 'CompleteWidget'
    },
    height: {
      type: Number,
      default: 66
    }
  },
  data () {
    return {
		width: 50,
		popupData: {},
        updateLogin: 0,
        showEditorDetail: false,
        allEntities: [],
        entityMap: [],
        metadataDetail: {},
        recordId: 0,
        mapping: [],
        disabled: [],
        selectedRecord: {},
        users: [],
        advertiserName: "",
        agencyName: "",
        productName: "",
        campaignName: "",
        campaignId: 0,
        list: [],
        searchFirstPlcm: true,
        searchFirst: true,
        tr: tr,
        getAppStyle,
        getFGColor
    }
  },
  directives:{
    ClickOutside,
  },
  
  methods: {
     close() {  this.$modal.hide(this.name); this.$emit("close") },
     getWidth() { return this.width+"%";},
     canSave()
     {
     	if ( !this.popupData.line.currencyId ) return false;
     	if ( !this.popupData.line.salesUnitId ) return false;
     	return true;
     },
     openInvEditor( filter, record, columnName, forceOpen)
     {
     	this.$emit( 'openInvEditor', filter, record, columnName, forceOpen);
     },
     getStyle(what)
     {
    	 if ( this.disabled.find(p=>p==what) )
    	 {
    		 return "width: 15%; text-decoration: line-through;";
    	 }
    	 return "width: 15%;";
     },
     disable(what)
     {
    	 if ( this.disabled.find(p=>p==what) )
    	 {
    		 this.disabled = this.disabled.filter(p => p !== what)
    	 }
    	 else
    	 {
    		 this.disabled.push(what);
    	 }
     },
     isDisabled(what)
     {
    	 return this.disabled.find(p => p === what);
     },
     getComplStyle(id)
     {
     	if (id) {
     		return "color: "+sessionStorage.fgColor;
     	}
     	return "color: #bbb;"
     },
     isCompleted()
     {
     	if ( !this.popupData.line.placementId ) return false;
     	if ( !this.popupData.line.campaignId ) return false;
     	//if ( this.popupData.line.status == 4 ) return false;
     	return true;
     },
     check(val)
     {
     	//console.log( val);
     },
     openWizard( ) {
    	this.$refs.placementWizard.open(this.popupData);
     },
     openCampaignWizard( ) {
    	this.$refs.campaignWizard.open(this.popupData);
     },
     campaignChanged() {
     	//alert(this.campaignId +"/"+this.popupData.line.campaignId)
     },
	 open(popupData) {
	    this.popupData = popupData;
	    this.$emit("open");
	    
	    //this.select = [];
	    //this.list = [];
	    
	    this.list['Campaign'] = [];
	    this.width=50;
	    this.searchFirst = true;
	    let that=this;
	    
	    this.$set( this, 'agencyName', popupData.line.agencyName);
	    this.$set( this, 'advertiserName', popupData.line.advertiserName);
	    this.$set( this, 'productName', popupData.line.productName);
	    
	    this.reload( "Currency", "Currency", "");
	    this.reload( "SalesUnit", "SalesUnit", "");
	    //
	    this.campaignId = popupData.line.campaignId;
	    if ( this.campaignId === null )
	    {
			this.campaignId = 0;
		}
	    this.reload( 'Campaign', 'Campaign', '');
	    
	    if (!this.campaignId)
	    {
	    	this.list['Placement'] = [];
	    }
	    
		if ( popupData.line.placementId )
	    {
	    	this.reload( 'Placement', 'Placement', '');
		}
		else
		{
			if ( popupData.line.campaignId )
		    {
		    	this.reload( 'Campaign', 'Campaign', '');
			}
		}
		
	 	this.$nextTick(function () {
		   	try {
		   	    this.$set( this, 'campaignName', popupData.line.name );
		   		that.$modal.show(that.name);
		   		
		   		setTimeout( 500, function() { that.$forceUpdate()});
		   	} catch (e) {
		   	//
		   	}

   		});
	 	

	 },
     getTableStyle() {
        
     	//return "overflow-y: scroll; height: calc(60vH - 104px)";
     	return "overflow-y: auto; height: calc("+this.height+"vH - 104px)";
     },

     
     closeEditorDetail()
	 {
	        this.showEditorDetail = false;
	 },
	 closeIf()
	 {
	 	if ( !this.showEditorDetail)
	 	{
	 		this.close();
	 	}
	 },
	 save()
	 {
	 	this.$emit("save", this.popupData.line, this.popupData.row, true)
	 },
	 completed()
	 {
	   
	 	this.$emit("completed", this.popupData.line, this.popupData.row, true)
	 },
	 
	 placementCreated( plcm)
	 {
	 	this.$emit( "addPlacement", this.popupData.line, plcm);
	 	this.reload( 'Placement', 'Placement', "");
		this.$forceUpdate();
	 },
	 campaignCreated( campaign)
	 {
	 	this.$emit( "addCampaign", this.popupData.line, campaign);
	 	this.$set( this.popupData.line, 'campaignId', campaign.id);
	 	this.$set( this.popupData.line, 'placementId', null);
	 	
	 	this.reload( 'Campaign', 'Campaign', "");
	 	this.reload( 'Placement', 'Placement', '');
		this.$forceUpdate();
	 },
	 reloadAll()
	 {
		this.reload( 'Advertiser', 'Client', this.advertiserName);
		this.reload( 'Agency', 'Client', this.agencyName);
		this.reload( 'Product', 'Product', this.productName);
	 },
	 searchCampaign()
	 { 
	 	this.campaignId=null;
	    this.$set( this, 'searchFirst', false );
	 	this.reload( 'Campaign', 'Campaign', '');
	 	
	 },
	 searchPlacement()
	 {
	 	this.reload( 'Placement', 'Placement', '');
	 	this.$set( this, 'searchFirstPlcm', false );
	 },
	 reload( what, entity, name)
	 {
	    //alert( what);
	 	let that = this;
	    let filter = { name: name};
	    if ( entity === 'Product' && this.popupData.line.advertiserId)
	    {
	    	filter.clientId = this.popupData.line.advertiserId;
	    }
	    if ( entity === 'Campaign' )
	    {
	    	if ( this.campaignId )
	    	{
	    		filter.id = this.campaignId;
	    	}
	    	else
	    	{

		    	if ( this.popupData.line.advertiserId) filter.clientId = this.popupData.line.advertiserId;
	    		if ( this.popupData.line.currencyId && !this.isDisabled('Currency')) filter.currencyId = this.popupData.line.currencyId;
		    	if ( this.popupData.line.agencyId) filter.agencyId = this.popupData.line.agencyId;
		    	if ( this.popupData.line.productId && !this.isDisabled('Product')) filter.productId = this.popupData.line.productId;
		    	if ( this.popupData.line.fromDate) filter.fromDate = new Date(this.popupData.line.fromDate).toISOString().split('T')[0];
		    	if ( this.popupData.line.untilDate) filter.untilDate = new Date(this.popupData.line.untilDate).toISOString().split('T')[0];
	    	}
	    	delete filter.name;
	    	this.searchFirstPlcm = true;
	    	//alert( JSON.stringify( filter))
	    	
	    }
	    if ( entity === 'Placement' )
	    {
	    	if ( this.popupData.line.placementId )
	    	{
	    	    filter.id = this.popupData.line.placementId;
	    	    if ( this.popupData.line.campaignId) filter.campaignId = this.popupData.line.campaignId;
	    	}
	    	else
	    	{
		    	if ( this.popupData.line.campaignId) filter.campaignId = this.popupData.line.campaignId;
		    	if ( this.popupData.line.typeId) filter.typeId = this.popupData.line.typeId;
		    	if ( this.popupData.line.fromDate) filter.fromDate = new Date(this.popupData.line.fromDate).toISOString().split('T')[0];
		    	if ( this.popupData.line.untilDate) filter.untilDate = new Date(this.popupData.line.untilDate).toISOString().split('T')[0];
	    	}
	    	delete filter.name;
	    	
	    	//alert( JSON.stringify( filter))
	    }
	    if ( entity === 'Currency' )
	    {
	    	//filter.id = this.popupData.line.currencyId;
	    	delete filter.name;
	    	//alert( JSON.stringify( filter))
	    }
	    if ( entity === 'SalesUnit' )
	    {
	    	//filter.id = this.popupData.line.currencyId;
	    	delete filter.name;
	    	//alert( JSON.stringify( filter))
	    }
	    let unitId = (this.popupData.line.salesUnitId);
	    unitId = (unitId) ? unitId : 0;
	    console.log( JSON.stringify( filter))
	 	HTTP.post( opsAPI+"/searchFor/"+entity+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+unitId+"/"+sessionStorage.userId+"/"+this.popupData.line.id, filter)	
    		 .then( response => 
             {
             	//alert( JSON.stringify(response.data));
             	that.$set( that.list, what, response.data.data);
             	if ( (!response.data.data || !response.data.data.length) && entity === 'Placement' )
			    {
			    	if ( that.popupData.line.placementId )
			    	{
			    		that.popupData.line.placementId = null;
			    	}
			    }
             	that.$nextTick(function () {
			   	try {
			   	   that.$forceUpdate();
					   	} catch (e) {
					   	//
					   	}
			
			   		});
				
             }).catch(e => {
                    showError( that.$toast, "loading "+name, e);
             });   
         
	 }
  },
  watch: {
		advertiserName: function() { this.reload( 'Advertiser', 'Client', this.advertiserName)},
		agencyName: function() { this.reload( 'Agency', 'Client', this.agencyName)},
		productName: function() { this.reload( 'Product', 'Product', this.productName)},
		campaignId: function() 
		{ 
			if ( this.popupData.line.campaignId != this.campaignId )
			{
				this.$set( this.list, 'Placement', []);
				this.popupData.line.placementId = null;
			}
			this.popupData.line.campaignId = this.campaignId; 
			this.reload( 'Campaign', 'Campaign', '');
			
		}
  },
  created() {
	initAppMode();
  }
}
</script>
<style scoped >

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.hTable {
  table-layout: fixed;
  width: 100%;;
}


.tdSmall {
  overflow: hidden;
  width: 5%;
}
.tdLarge {
  width: 25%;
  overflow: hidden;
}
.tdXLarge {
  width: 40%;
  overflow: hidden;
}
.appLeft {
	display:  inline-table; 
	text-align: right;
	width: 100%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 50%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.history {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400 !important;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.mainContent {
  position: relative;
  height: 100% !important;
  
  float: top;
} 
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
}
.btn-small {
	height: 14pt;
}
.btn-save {
	background-color: #080 !important;
    color: #fff !important;
}
.button {
    background-color: white;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.7em - 1px);
    padding-left: .7em;
    padding-right: .7em;
    padding-top: calc(0.7em - 1px);
    text-align: center;
    white-space: nowrap;
    font-size: 10pt;
}
.button:hover {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border: 1pt outset grey;
    background-color: #e8f0e8;
}
.modal-wrapper {

  vertical-align: middle;
}
td { 
    padding-right: 8px;
}
.modal-container {

  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  //background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
